import React from 'react';

export const BoardStatusBarMineIcon = () => (
    <svg
        width="43"
        height="43"
        viewBox="0 0 43 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.08325 26.5625C7.08325 29.3644 7.91411 32.1034 9.47077 34.4331C11.0274 36.7628 13.2399 38.5786 15.8286 39.6508C18.4172 40.723 21.2656 41.0036 24.0137 40.457C26.7618 39.9103 29.286 38.5611 31.2673 36.5798C33.2485 34.5986 34.5978 32.0743 35.1444 29.3263C35.691 26.5782 35.4105 23.7298 34.3382 21.1412C33.266 18.5525 31.4502 16.34 29.1205 14.7833C26.7908 13.2267 24.0518 12.3958 21.2499 12.3958C17.494 12.4001 13.8931 13.894 11.2372 16.5498C8.58138 19.2057 7.08747 22.8066 7.08325 26.5625ZM21.2499 15.9375C23.3513 15.9375 25.4056 16.5606 27.1529 17.7281C28.9001 18.8956 30.262 20.555 31.0661 22.4965C31.8703 24.438 32.0807 26.5743 31.6708 28.6353C31.2608 30.6964 30.2489 32.5896 28.7629 34.0755C27.277 35.5614 25.3838 36.5734 23.3228 36.9833C21.2617 37.3933 19.1254 37.1829 17.1839 36.3787C15.2424 35.5745 13.583 34.2127 12.4156 32.4654C11.2481 30.7182 10.6249 28.6639 10.6249 26.5625C10.6277 23.7454 11.7481 21.0446 13.74 19.0526C15.732 17.0606 18.4329 15.9403 21.2499 15.9375Z"
            fill="#7B9FB9"
        />
        <path
            d="M21.2501 33.6458C21.7197 33.6458 22.1702 33.4593 22.5022 33.1272C22.8343 32.7951 23.0209 32.3447 23.0209 31.875C23.0209 31.4053 22.8343 30.9549 22.5022 30.6228C22.1702 30.2907 21.7197 30.1042 21.2501 30.1042C20.3108 30.1042 19.4099 29.731 18.7457 29.0668C18.0816 28.4026 17.7084 27.5018 17.7084 26.5625C17.7084 26.0928 17.5218 25.6424 17.1897 25.3103C16.8577 24.9782 16.4072 24.7917 15.9376 24.7917C15.4679 24.7917 15.0175 24.9782 14.6854 25.3103C14.3533 25.6424 14.1667 26.0928 14.1667 26.5625C14.1667 28.4411 14.913 30.2428 16.2414 31.5712C17.5698 32.8996 19.3715 33.6458 21.2501 33.6458Z"
            fill="#DD6E41"
        />
        <path
            d="M33.6458 12.3958C34.1155 12.3958 34.5659 12.2093 34.898 11.8772C35.2301 11.5451 35.4167 11.0947 35.4167 10.625V8.85417C35.4167 6.97555 34.6704 5.17388 33.342 3.8455C32.0136 2.51711 30.2119 1.77084 28.3333 1.77084H26.5625C24.9967 1.7767 23.477 2.30123 22.2409 3.26241C21.0048 4.22359 20.1221 5.56725 19.7306 7.08334H17.7083C17.2387 7.08334 16.7883 7.26991 16.4562 7.602C16.1241 7.9341 15.9375 8.38452 15.9375 8.85417C15.9375 9.32382 16.1241 9.77424 16.4562 10.1063C16.7883 10.4384 17.2387 10.625 17.7083 10.625H24.7917C25.2613 10.625 25.7117 10.4384 26.0438 10.1063C26.3759 9.77424 26.5625 9.32382 26.5625 8.85417C26.5625 8.38452 26.3759 7.9341 26.0438 7.602C25.7117 7.26991 25.2613 7.08334 24.7917 7.08334H23.5131C23.8215 6.5467 24.2656 6.1006 24.8008 5.78978C25.336 5.47897 25.9436 5.31437 26.5625 5.3125H28.3333C29.2726 5.3125 30.1735 5.68564 30.8377 6.34983C31.5019 7.01402 31.875 7.91486 31.875 8.85417V10.625C31.875 11.0947 32.0616 11.5451 32.3937 11.8772C32.7258 12.2093 33.1762 12.3958 33.6458 12.3958Z"
            fill="#7B9FB9"
        />
    </svg>
);
