export const GAME_STATUS = {
    DEFAULT: 'halt',
    STARTED: 'started',
    WON: 'won',
    LOST: 'lost'
};

export const GAME_CONFIG = {
    BOARD_SIZE: 9,
    MINES_NR: 10
};

export const CONFETTI_NUMBER = 10;

export const CELL_TEST_ID = 'c-cell--';
export const BOARD_STATUS_TEST_ID = 'Game status: ';
