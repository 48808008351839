import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GAME_STATUS } from '../../utils';
import { BoardStatusBarRetryIcon } from './BoardStatusBarRetryIcon';
import { BoardStatusBarMine } from '../BoardStatusBarMine';
import { BoardStatusBarClock } from '../BoardStatusBarClock';

import './BoardStatusBar.scss';

export const BoardStatusBar = ({
    flagsCount,
    minesNr,
    gameStatus,
    restartGame
}) => {
    const plantedFlags = minesNr - flagsCount;
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);

    const isGameFinished =
        gameStatus === GAME_STATUS.LOST || gameStatus === GAME_STATUS.WON;
    const isGameStarted = gameStatus === GAME_STATUS.STARTED;

    const startTimer = () => {
        setIsActive(true);
    };

    const resetTimer = () => {
        setIsActive(false);
        setSeconds(0);
    };

    if (isGameStarted) {
        !isActive && startTimer();
    }

    if (isGameFinished) {
        isActive && setIsActive(false);
    }

    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                setSeconds(s => s + 1);
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    return (
        <div className="c-board-status-bar t-color-blue o-layout-justify-between o-layout-align-center">
            <div className="c-board__title u-font-2xlg">Minesweeper</div>
            <div
                className="u-cursor-pointer o-layout-justify-self-end"
                onClick={() => {
                    restartGame();
                    resetTimer();
                }}
            >
                <BoardStatusBarRetryIcon />
            </div>
            <BoardStatusBarClock seconds={seconds} />
            <BoardStatusBarMine plantedFlags={plantedFlags} />
        </div>
    );
};

BoardStatusBar.propTypes = {
    flagsCount: PropTypes.number.isRequired,
    minesNr: PropTypes.number.isRequired,
    gameStatus: PropTypes.string.isRequired,
    restartGame: PropTypes.func.isRequired
};
