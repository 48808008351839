import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    generateBoard,
    revealAllCellsDefeat,
    revealAllCellsVictory,
    isGameWon,
    allMinesFlagged,
    revealCells,
    flagSwitch,
    getRevealableNeighbours,
    isGray
} from './Board.utils';
import { GAME_STATUS, BOARD_STATUS_TEST_ID } from '../../utils';
import { Cell } from '../Cell';
import { BoardStatusBar } from '../BoardStatusBar';
import { Confetti } from '../Confetti';

import './Board.scss';

export const Board = ({ boardSize, minesNr }) => {
    const [board, setBoard] = useState(generateBoard({ boardSize, minesNr }));
    const [gameStatus, setGameStatus] = useState(GAME_STATUS.DEFAULT);
    const [flagsCount, setFlagsCount] = useState(0);

    const startGame = () => {
        if (gameStatus === GAME_STATUS.DEFAULT) {
            setGameStatus(GAME_STATUS.STARTED);
        }
    };

    const isGameFinished =
        gameStatus === GAME_STATUS.LOST || gameStatus === GAME_STATUS.WON;

    const rightClick = ({ e, x, y }) => {
        e.preventDefault();
        const currentCell = board[x][y];
        const isCellFlag = currentCell.isFlag;

        if (isGameFinished) {
            return;
        }

        startGame();

        if (currentCell.isRevealed) {
            return;
        }

        const newFlagsCount = !isCellFlag ? flagsCount + 1 : flagsCount - 1;

        setFlagsCount(newFlagsCount);

        const newBoard = flagSwitch({ board, x, y, isCellFlag });

        setBoard(newBoard);

        if (
            allMinesFlagged({ board: newBoard, minesNr }) &&
            newFlagsCount === minesNr
        ) {
            setGameStatus(GAME_STATUS.WON);
            setBoard(revealAllCellsVictory(newBoard));
        } else {
            setBoard(newBoard);
        }
    };

    const leftClick = ({ x, y }) => {
        const currentCell = board[x][y];
        let newBoard;

        if (isGameFinished) {
            return;
        }

        startGame();

        if (currentCell.isRevealed) {
            return;
        }

        if (currentCell.isFlag) {
            return;
        }

        if (currentCell.isMine) {
            setGameStatus(GAME_STATUS.LOST);
            setBoard(revealAllCellsDefeat(board));
            return;
        }

        // if it is a gray cell, we would like to try to reveal it's neighbours, too
        if (isGray(currentCell)) {
            newBoard = revealCells(
                [...getRevealableNeighbours(currentCell, board), currentCell],
                board
            );
            // we only reveal the cell that was clicked
        } else {
            newBoard = revealCells([currentCell], board);
        }

        if (isGameWon(newBoard)) {
            setGameStatus(GAME_STATUS.WON);
            setBoard(revealAllCellsVictory(newBoard));
        } else {
            setBoard(newBoard);
        }
    };

    const restartGame = () => {
        setBoard(generateBoard({ boardSize, minesNr }));
        setGameStatus(GAME_STATUS.DEFAULT);
        setFlagsCount(0);
    };

    return (
        <>
            {(gameStatus === GAME_STATUS.WON ||
                gameStatus === GAME_STATUS.LOST) && (
                <Confetti gameStatus={gameStatus} />
            )}
            <div
                className={classNames(
                    'c-board',
                    'c-board--aspect-ratio',
                    't-background-black',
                    't-border-r',
                    'u-font-bold'
                )}
            >
                <BoardStatusBar
                    flagsCount={flagsCount}
                    minesNr={minesNr}
                    gameStatus={gameStatus}
                    restartGame={restartGame}
                />
                <div
                    className="c-board__cells c-board__cells--grid-area"
                    data-testid={`${BOARD_STATUS_TEST_ID}${gameStatus}`}
                >
                    {board.map((row, x) =>
                        row.map((cell, y) => {
                            return (
                                <Cell
                                    key={`${x} ${y}`}
                                    cell={cell}
                                    onClick={() => leftClick({ x, y })}
                                    onContextMenu={e => rightClick({ e, x, y })}
                                />
                            );
                        })
                    )}
                </div>
            </div>
        </>
    );
};

Board.propTypes = {
    boardSize: PropTypes.number.isRequired,
    minesNr: PropTypes.number.isRequired
};
