import { flatten } from '../../utils';

const getRandomInt = max => Math.round(Math.random() * 1000) % max;

const createDefaultBoard = size => {
    return [...Array(size)].map((_, y) =>
        [...Array(size)].map((__, x) => ({
            isRevealed: false,
            adjacentMines: 0,
            isMine: false,
            isFlag: false,
            x,
            y
        }))
    );
};

export const generateBoard = ({ boardSize, minesNr }) => {
    const board = createDefaultBoard(boardSize);
    let minesToPlant = minesNr;

    // planting mines
    while (minesToPlant > 0) {
        const randX = getRandomInt(boardSize);
        const randY = getRandomInt(boardSize);

        // todo: refactor
        if (!board[randX][randY].isMine) {
            board[randX][randY].isMine = true;
            for (let i = randX - 1; i <= randX + 1; i += 1) {
                if (i !== -1 && i < boardSize) {
                    for (let j = randY - 1; j <= randY + 1; j += 1) {
                        if (j !== -1 && j < boardSize) {
                            board[i][j].adjacentMines += 1;
                        }
                    }
                }
            }

            minesToPlant -= 1;
        }
    }

    return board;
};

export const revealAllCellsDefeat = board =>
    board.map(row =>
        row.map(cell => {
            return {
                ...cell,
                isRevealed: true
            };
        })
    );

export const revealAllCellsVictory = board =>
    board.map(row =>
        row.map(cell => {
            return {
                ...cell,
                isFlag: cell.isMine,
                isRevealed: !cell.isMine
            };
        })
    );

export const isGameWon = board =>
    board.every(row =>
        row.every(cell => (cell.isMine && !cell.isRevealed) || cell.isRevealed)
    );

export const allMinesFlagged = ({ board, minesNr }) => {
    let flaggedMines = 0;
    board.forEach(row =>
        row.forEach(cell => {
            if (cell.isFlag && cell.isMine) {
                flaggedMines += 1;
            }
        })
    );

    return flaggedMines === minesNr;
};

export const hasCell = (cell, cells = []) =>
    cells.some(c => c.x === cell.x && c.y === cell.y);

// Returns with a new board where the cells passed in as an argument are revealed.
// cells = [{ x, y, ... }, { x, y, ... }]
export const revealCells = (cells, board) =>
    board.map((row, y) =>
        row.map((cell, x) => {
            // Is the current cell to be revealed?
            if (hasCell({ x, y }, cells)) {
                return {
                    ...cell,
                    isRevealed: true
                };
            }

            return cell;
        })
    );

export const flagSwitch = ({ board, x, y, isCellFlag }) =>
    board.map((row, rowIndex) =>
        row.map((cell, cellIndex) => {
            if (x === rowIndex && y === cellIndex) {
                return {
                    ...cell,
                    isFlag: !isCellFlag
                };
            }

            return cell;
        })
    );

export const isGray = cell =>
    !cell.isFlag && !cell.isMine && cell.adjacentMines === 0;

export const isGrayOrNumber = cell => !cell.isFlag && !cell.isMine;

export const getNeighbourCells = (current, board) => {
    return flatten(board).filter(cell => {
        const { x, y } = cell;

        if (y === current.y && x === current.x) {
            return false;
        }

        if (
            y <= current.y + 1 &&
            y >= current.y - 1 &&
            x <= current.x + 1 &&
            x >= current.x - 1
        ) {
            return true;
        }

        return false;
    });
};

export const removeCell = (cell, cells) =>
    cells.filter(c => !(c.x === cell.x && c.y === cell.y));

/**
 *  💣      1       X       1       💣

    1       1       X       2       2

    X       X       X       2       💣

    1       1       X       2       💣

    💣      1       X       1       1

*/

export const getRevealableNeighbours = (currentCell, board, cells = []) => {
    if (isGrayOrNumber(currentCell)) {
        const neighbours = getNeighbourCells(currentCell, board); // [Cell, Cell, ...]
        const revealableNeighbours = neighbours.filter(isGrayOrNumber); // The cells we can reveal already

        revealableNeighbours.forEach(cell => {
            if (!hasCell(cell, cells)) {
                cells.push(cell);

                if (isGray(cell)) {
                    getRevealableNeighbours(cell, board, cells);
                }
            }
        });

        // Remove the initially clicked cell
        return removeCell(currentCell, cells);
    }

    return [];
};
