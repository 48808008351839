import React from 'react';
import PropTypes from 'prop-types';
import { GAME_STATUS, CONFETTI_NUMBER } from '../../utils';

import './confetti.scss';

export const Confetti = ({ gameStatus }) => {
    const ConfettiToRender = () => (
        <div className="c-confetti__item">
            {gameStatus === GAME_STATUS.WON ? '🎉' : '☠️'}
        </div>
    );

    return (
        <div className="c-confetti u-font-lg">
            {[...Array(CONFETTI_NUMBER)].map((_, i) => (
                <ConfettiToRender key={i} />
            ))}
        </div>
    );
};

Confetti.propTypes = {
    gameStatus: PropTypes.string.isRequired
};
