import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CELL_TEST_ID } from '../../utils';

const CellToRender = cell => {
    if (cell.isMine && cell.isRevealed) {
        return '☠️';
    }

    if (!cell.isRevealed && cell.isFlag) {
        return '🏴';
    }

    if (cell.isRevealed) {
        return cell.adjacentMines > 0 ? cell.adjacentMines : '';
    }

    return null;
};

export const Cell = ({ cell, onClick, onContextMenu }) => {
    const className = classNames(
        'c-cell t-color-yellow',
        'u-font-md',
        'o-layout-align-center',
        'o-layout-justify-center',
        'o-layout-flex',
        'o-layout-flex-shrink-1',
        't-border-r',
        {
            't-background-black':
                cell.isRevealed && (!cell.adjacentMines || cell.isMine),
            't-background-red': cell.isMine && cell.isRevealed,
            't-background-orange u-cursor-pointer': !cell.isRevealed,
            'u-cursor-initial': cell.isRevealed
        }
    );

    return (
        <div
            className={className}
            onClick={onClick}
            onContextMenu={onContextMenu}
            data-testid={`${CELL_TEST_ID}${cell.y}-${cell.x}`}
        >
            {CellToRender(cell)}
        </div>
    );
};

Cell.propTypes = {
    cell: PropTypes.shape({
        isFlag: PropTypes.bool.isRequired,
        isRevealed: PropTypes.bool.isRequired,
        adjacentMines: PropTypes.number.isRequired,
        isMine: PropTypes.bool.isRequired,
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    onContextMenu: PropTypes.func.isRequired
};
