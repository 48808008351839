import React from 'react';
import PropTypes from 'prop-types';
import { BoardStatusBarClockIcon } from './BoardStatusBarClockIcon';

import './BoardStatusBarClock.scss';

export const BoardStatusBarClock = ({ seconds }) => (
    <div className="u-font-lg">
        <span className="c-board-status-bar-clock__widget c-board-status-bar-clock__icon">
            <BoardStatusBarClockIcon />
        </span>
        <span className="c-board-status-bar-clock__widget">{`${seconds}s`}</span>
    </div>
);

BoardStatusBarClock.propTypes = {
    seconds: PropTypes.number.isRequired
};
