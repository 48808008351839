import React from 'react';
import PropTypes from 'prop-types';
import { BoardStatusBarMineIcon } from './BoardStatusBarMineIcon';

import './BoardStatusBarMine.scss';

export const BoardStatusBarMine = ({ plantedFlags }) => (
    <div className="u-font-lg">
        <span className="c-board-status-bar-clock__widget c-board-status-bar-clock__icon">
            <BoardStatusBarMineIcon />
        </span>
        <span className="c-board-status-bar-clock__widget">{`${plantedFlags}`}</span>
    </div>
);

BoardStatusBarMine.propTypes = {
    plantedFlags: PropTypes.number.isRequired
};
