import React from 'react';

export const BoardStatusBarRetryIcon = () => (
    <svg
        width="68"
        height="82"
        viewBox="0 0 78 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_dd)">
            <rect x="6" y="6" width="68" height="68" rx="34" fill="#1F1B18" />
        </g>
        <path
            d="M28.6355 35.2514C29.9012 32.3776 32.0783 30.0008 34.8304 28.4884C37.5824 26.9761 40.756 26.4124 43.8605 26.8846C46.965 27.3569 49.8275 28.8387 52.0053 31.101C54.1832 33.3633 55.5551 36.28 55.909 39.4003C56.2628 42.5205 55.5789 45.6705 53.963 48.363C52.3471 51.0556 49.8892 53.1407 46.9692 54.2962C44.0493 55.4517 40.8301 55.613 37.8093 54.7553C34.7885 53.8977 32.1343 52.0687 30.2572 49.5513"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="square"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 24V41.0107H40.0106V34.2064H29.8042V24H23Z"
            fill="white"
        />
        <defs>
            <filter
                id="filter0_dd"
                x="0"
                y="0"
                width="78"
                height="82"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dx="-2" dy="-2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow"
                    result="effect2_dropShadow"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
