import React from 'react';

export const BoardStatusBarClockIcon = () => (
    <svg
        width="30"
        height="35"
        viewBox="0 0 30 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 19.9291C1 27.5831 7.27586 33.802 15 33.802C22.7241 33.802 29 27.5831 29 19.9291C29 16.1287 27.4444 12.6472 24.9234 10.149L26.023 9.05941L26.5326 9.56436C26.7471 9.77697 27.0153 9.88328 27.3103 9.88328C27.6054 9.88328 27.8736 9.77697 28.0881 9.56436C28.5172 9.13914 28.5172 8.44816 28.0881 8.02293L25.5402 5.49818C25.1111 5.07296 24.4138 5.07296 23.9847 5.49818C23.5556 5.9234 23.5556 6.61439 23.9847 7.03961L24.4943 7.54456L23.2874 8.7405C21.249 7.25222 18.8084 6.32205 16.1264 6.10944V3.17926H18.567C19.1839 3.17926 19.6667 2.70089 19.6667 2.08963C19.6667 1.47837 19.1839 1 18.567 1H11.3257C10.7088 1 10.2261 1.47837 10.2261 2.08963C10.2261 2.70089 10.7088 3.17926 11.3257 3.17926H13.9272V6.10944C6.68582 6.66754 1 12.6472 1 19.9291ZM15 8.23554C21.5172 8.23554 26.8276 13.4977 26.8276 19.9557C26.8276 26.4138 21.5172 31.6759 15 31.6759C8.48276 31.6759 3.17241 26.4138 3.17241 19.9557C3.17241 13.4711 8.48276 8.23554 15 8.23554Z"
            fill="#7B9FB9"
            stroke="#7B9FB9"
        />
        <path
            d="M15 20.5324H19.6935C20.3104 20.5324 20.7931 20.0541 20.7931 19.4428C20.7931 18.8316 20.3104 18.3532 19.6935 18.3532H16.0996V12.7456C16.0996 12.1343 15.6169 11.6559 15 11.6559C14.3831 11.6559 13.9004 12.1343 13.9004 12.7456V19.4694C13.9004 20.0541 14.3831 20.5324 15 20.5324Z"
            fill="#DD6E41"
        />
    </svg>
);
